import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SidebarDoc from '../components/SidebarDoc'

const IndexPage = (props) => (
  <Layout>
    <Helmet>
      <body className="home" />
    </Helmet>

    <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
         description="The tools you need to build Proof of Process apps." />

    <SidebarDoc />

    <main className="content" id="intro">
      <h2>All you need to build Proof of Process apps.</h2>

      <section className="doc">
        <h3>What is Proof of Process?</h3>
        <p>Proof of Process (PoP) is a protocol that allows participants to trust a common process by immutably recording who did what, when and in which order.</p>

        <p>This is accomplished through the creation of a cryptographic audit trail that records these key factual elements of every step of a process. It can be shared easily, independently verified, and thus provides a backbone of trust upon which participants in Proof of Process Networks can rely.</p>

        <p>You can find out more by reading the <a href="https://proofofprocess.org/" target="_blank" rel="noopener norefferer">PoP White Paper</a>.</p>

        <p><Link className="button" to="/doc/get-started/">Get Started</Link></p>
      </section>
    </main>
  </Layout>
)

export default IndexPage
