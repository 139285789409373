import React from 'react'
import { Link } from 'gatsby'

import PartialNavLink from '../../components/PartialNavLink'
import GetStartedIcon from '../../svg/get_started.svg'
import ConceptIcon from '../../svg/concept.svg'
import LibraryIcon from '../../svg/library.svg'

const SidebarDoc = () => (
  <nav id="sidebar">
    <ul>
      <li>
        <PartialNavLink to="/doc/get-started/">
          <GetStartedIcon className="getStartedIcon" /> Get Started
          <ul>
            <li>
              <Link activeClassName="active" to="/doc/get-started/">
                Introduction
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/doc/get-started/chainscript/">
                Chainscript
              </Link>
            </li>
            <li>
              <Link
                activeClassName="active"
                to="/doc/get-started/asset-tracker/"
              >
                Asset Tracker Example
              </Link>
            </li>
            <li>
              <Link
                activeClassName="active"
                to="/doc/get-started/going-further/"
              >
                Going further
              </Link>
            </li>
          </ul>
        </PartialNavLink>
      </li>
      <li>
        <PartialNavLink to="/doc/concepts/">
          <ConceptIcon className="conceptIcon" /> Concepts
          <ul>
            <li>
              <Link activeClassName="active" to="/doc/concepts/chainscript/">
                Chainscript
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/doc/concepts/store/">
                Store
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/doc/concepts/fossilizer/">
                Fossilizer
              </Link>
            </li>
          </ul>
        </PartialNavLink>
      </li>

      <li>
        <PartialNavLink to="/doc/api/">
          <LibraryIcon className="libraryIcon" /> Libraries & APIs
          <ul>
            <li>
              <Link activeClassName="active" to="/doc/api/chainscript/js/">
                Chainscript JS
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/doc/api/chainscript/go/">
                Chainscript Go
              </Link>
            </li>

            <li>
              <Link activeClassName="active" to="/doc/api/store/js/">
                Store Client
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/doc/api/store/go/">
                Store Go API
              </Link>
            </li>

            <li>
              <Link activeClassName="active" to="/doc/api/fossilizer/js/">
                Fossilizer Client
              </Link>
            </li>

            <li>
              <Link activeClassName="active" to="/doc/api/fossilizer/go/">
                Fossilizer Go API
              </Link>
            </li>
          </ul>
        </PartialNavLink>
      </li>
    </ul>
  </nav>
)

export default SidebarDoc
